import React, { useEffect } from "react"
import '../assets/fonts/Poppins/Poppins-Light.ttf';
import '../assets/fonts/Poppins/Poppins-Bold.ttf';
import '../assets/fonts/Poppins/Poppins-Regular.ttf';
import "../components/layout.css";
import Metas from "../components/Ebooks/Metas";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";

const Ebooks = () => {
  useEffect(()=>{
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Ebook",
      // string - required - Type of interaction (e.g. 'play')
      action: "View Page Ebook",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "view-metas-e-planejamento",
      // number - optional - Numeric value associated with the event. (e.g. A product ID)
      value: 'metas-e-planejamento'
    })
  },[])
  return <Metas/>
}

export default Ebooks
